var canvas = null;

function setup() {
  const logo = document.querySelector('svg#logo')
  const main = document.querySelector('main');
  canvas = createCanvas(logo.getBoundingClientRect().width,logo.getBoundingClientRect().height);
  //canvas.position(logo.getBoundingClientRect().top, logo.getBoundingClientRect().left)
  main.style.width = logo.getBoundingClientRect().width + 'px';
  main.style.height = logo.getBoundingClientRect().height + 'px';
  main.style.top = logo.getBoundingClientRect().top + 'px';
  main.style.left = logo.getBoundingClientRect().left + 'px';
	background(0);
	noiseDetail(10);
}
function repos() {
  if(!canvas) return;
  const logo = document.querySelector('svg#logo')
  const main = document.querySelector('main');
  canvas.width = logo.getBoundingClientRect().width;
  canvas.height = logo.getBoundingClientRect().height;
  main.style.width = logo.getBoundingClientRect().width + 'px';
  main.style.height = logo.getBoundingClientRect().height + 'px';
  main.style.top = logo.getBoundingClientRect().top + 'px';
  main.style.left = logo.getBoundingClientRect().left + 'px';
}

function draw() {
	background(23,12,232);
	stroke(255, 255, 255, 128);
	noFill();
	var zoff=frameCount*0.001;
	var yoff=-10;
	for(var y=0;y<height*1.1;y+=10){
		var xoff=-10;
		beginShape();
		for(var x=-10;x<width*1.1;x+=10){
			var n=noise(xoff,yoff,zoff);
			var wave=map(n,0,1,-200,200);
			curveVertex(x,y+wave);
			//ellipse(x,y+wave,30);
		
			xoff+=0.01;
		}
		endShape();
		yoff+=0.09;
	}
}

function windowResize() {
  repos();
}
var timeout = null;
window.addEventListener('resize', function () {
  clearTimeout(timeout);
  timeout = setTimeout(function () {
    repos();
  }, 100)
})