
const svg = SVG('#logo');
const path = svg.findOne('path');


document.querySelector('[href="#expertise"]').addEventListener('click', function(e) {
  e.preventDefault(); 
  document.querySelector('#imprint').classList.remove('open');
  document.querySelector('#expertise').classList.toggle('open');
  this.classList.toggle('active');
})

document.querySelector('[href="#imprint"]').addEventListener('click', function(e) {
  e.preventDefault(); 
  document.querySelector('#expertise').classList.remove('open');
  document.querySelector('#imprint').classList.toggle('open');
  this.classList.toggle('active');
})

document.querySelector('#imprint .close').addEventListener('click', function(e) {
  e.preventDefault(); 
  document.querySelector('#expertise').classList.remove('open');
  document.querySelector('#imprint').classList.remove('open');
  this.classList.remove('active');
})